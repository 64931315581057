<template>
  <div class="add-sensor">
    <v-btn class="ma-3" v-if="!scanner && !newSensor" @click="startScanner">Add Sensor with QR Code</v-btn>
    <v-btn class="ma-3" v-if="!scanner && !newSensor" @click="newSensor = true">Add Sensor manually</v-btn>
    <vue-qr-reader
      v-if="scanner"
      v-on:code-scanned="codeArrived"
      :responsive="true"
      :draw-on-found="false"
    />
    <v-form v-if="!scanner && newSensor" class="pb-5" ref="form" v-model="valid" lazy-validation>
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field :color="siteColor" label="Name" hide-details v-model="sensor.name" required></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field :color="siteColor" label="UUID" hide-details v-model="sensor.uuid" required></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field :color="siteColor" label="Code" hide-details v-model="sensor.code" required></v-text-field>
          </v-col>
          <!--  <v-col cols="12" md="6">
            <v-text-field label="Part #" hide-details v-model="sensor.partNumber" required></v-text-field>
          </v-col>-->
          <!-- <v-col cols="12" md="6">
            <v-select
              label="Model"
              return-object
              item-text="name"
              item-value="value"
              hide-details
              :items="models"
              v-model="sensor.model"
              required
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field label="Harness #" hide-details v-model="sensor.harnessNumber" required></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              return-object
              label="Store"
              item-text="name"
              item-value="value"
              hide-details
              :items="stores"
              v-model="sensor.store"
              required
              @change="storeChange"
            ></v-select>
          </v-col>-->
          <!-- <v-col cols="12" md="6">
            <v-select
              return-object
              label="Location"
              item-text="name"
              item-value="value"
              hide-details
              :items="locations"
              v-model="sensor.location"
              required
            ></v-select>
          </v-col>-->

          <!-- <v-col>
            <VueGooglePlaces
              ref="gplaces"
              :api-key="apiKey"
              version="3.38"
              types="establishment"
              country="us"
              placeholder="Location"
              @placechanged="onPlaceChanged"
              @noresult="onNoResult"
              class="v-input v-text-field"
            />
          </v-col>-->
        </v-row>
        <v-btn class="mt-3" :color="siteColor" dark @click="addSensor">Save</v-btn>
      </v-container>
    </v-form>
    <v-data-table
      v-if="sensors.length && !newSensor"
      hide-default-footer
      :headers="headers"
      :items="sensors"
      :items-per-page="30"
      item-key="id"
      class="elevation-1"
    >
      <template v-slot:body="{items}">
        <tbody>
          <template v-for="item in items">
            <tr @click="selectRow(item)" :key="item.id">
              <td>{{ item.serial }}</td>
              <!-- <td>{{ item.id }}</td> -->
              <!-- <td>{{ item.location.name }}</td> -->
              <!-- <td>
                <v-chip
                  class="mx-1"
                  close
                  label
                  @click:close="removeTag(tag.sensorTagId)"
                  :key="tag.id"
                  v-for="tag in item.tags"
                >{{ tag.name }}</v-chip>
              </td>-->
            </tr>
            <!-- <tr class="py-2" v-show="item.id === expandedItem.id">
              <td colspan="4">
                <v-row>
                  <v-col>
                    <v-combobox
                      v-model="chips"
                      :items="tags"
                      single-line
                      item-text="name"
                      item-value="name"
                      :return-object="false"
                      chips
                      label="Tags"
                      multiple
                    >
                      <template v-slot:selection="{ attrs, item, select, selected }">
                        <v-chip
                          :key="item"
                          :input-value="selected"
                          close
                          @click="select"
                          @click:close="remove(item)"
                        >
                          <strong>{{ item }}</strong>
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-btn class="mb-3" color @click="addTag">Add Tags</v-btn>
              </td>
            </tr>-->
          </template>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import VueQrReader from "vue-qr-reader/dist/lib/vue-qr-reader.umd.js";
export default {
  components: {
    VueQrReader
  },
  data() {
    return {
      scanner: false,
      expandedItem: "",
      newSensor: false,
      chips: [],
      apiKey: "AIzaSyCACV_0wSp8UyW5-WpJAw-qI3OBCSRVVnw",
      valid: true,
      models: [
        {
          name: "C314",
          value: "921f6417-7d77-4860-bc97-f9b0e3959780"
        }
      ],
      headers: [
        {
          text: "Serial",
          align: "left",
          value: "serial"
        }
        // { text: "ID", value: "id" },
        // { text: "Location", value: "location.name" },
        // { text: "Tags", sortable: false, value: "tags" }
      ],
      sensor: {
        label: "",
        uuid: "",
        location: "",
        store: "",
        model: "",
        serialNumber: "",
        partNumber: "",
        harnessNumber: ""
      },
      sensorCopy: {
        label: "",
        uuid: "",
        location: "",
        store: "",
        model: "",
        serialNumber: "",
        partNumber: "",
        harnessNumber: ""
      },
      // stores: [
      //   {
      //     name: "Kappus",
      //     value: "ca709d95-3aa3-4324-a590-f0f3d52b2ead"
      //   },
      //   {
      //     name: "Kappus2",
      //     value: "71d349ee-2958-4125-a3e9-8a357e2e24c9"
      //   }
      // ],
      tag: ""
    };
  },
  async created() {
    this.$store.dispatch("user/getUserDetails", this.user.username);
    this.sensor.store = this.stores[0];
    this.sensor.location = this.locations[0];
    this.sensor.model = this.models[0];
  },
  computed: {
    ...mapState("sensor", ["sensors", "tags"]),
    ...mapState("user", ["user", "userDetails"]),
    ...mapGetters("user", [
      "isSuper",
      "isDistributor",
      "isStore",
      "isAdmin",
      "isUser",
      "userDistributorName"
    ]),
    stores() {
      if (this.isSuper) {
        return [
          {
            name: "BWR",
            value: "966a0282-7f9e-496b-b66a-0ff6025a59a5"
          },
          {
            name: "Kappus",
            value: "ca709d95-3aa3-4324-a590-f0f3d52b2ead"
          },
          {
            name: "Kappus2",
            value: "71d349ee-2958-4125-a3e9-8a357e2e24c9"
          }
        ];
      } else if (this.isDistributor) {
        if (this.userDistributorName === "kappus") {
          return [
            {
              name: "Kappus",
              value: "ca709d95-3aa3-4324-a590-f0f3d52b2ead"
            },
            {
              name: "Kappus2",
              value: "71d349ee-2958-4125-a3e9-8a357e2e24c9"
            }
          ];
        } else
          return [
            {
              name: "BWR",
              value: "966a0282-7f9e-496b-b66a-0ff6025a59a5"
            }
          ];
      }
    },
    locations() {
      if (this.sensor.store.name === "Kappus") {
        return [
          {
            name: "Kappus",
            value: "8847351d-9e4c-42a2-8df3-c2da417e1227"
          },
          {
            name: "Kappus2",
            value: "5fe0cc15-5857-45c9-b5ef-632df18ea558"
          }
        ];
      } else if (this.sensor.store.name === "Kappus2") {
        return [
          {
            name: "Kappus3",
            value: "b5187b1c-b0ed-489d-8092-5cfb465add0d"
          },
          {
            name: "Kappus4",
            value: "e39ffd02-5b65-442d-b3ff-8994d5ebbdb3"
          }
        ];
      } else
        return [
          {
            name: "BWR",
            value: "02631b34-e603-44fa-9e96-3806d10ff342"
          }
        ];
    },
    tagNames() {
      return this.tags.map(item => item.name);
    }
    // currentTags() {
    //   return this.expandedItem.tags.map(item => item.name);
    // }
    // currentSensorTags() {
    //   return this.tags.filter(item => !this.sensors[1].tags.includes(item));
    // }
  },
  methods: {
    storeChange(item) {
      this.sensor.location = this.locations[0];
    },
    codeArrived(code) {
      this.scanner = false;
      let [uuid, parts] = code.split("P");
      let [_, number] = parts.split("#");
      this.sensor.uuid = uuid.trim();
      this.sensor.partNumber = number.trim();
    },
    async removeTag(sensorTagId) {
      await this.$store.dispatch("sensor/removeSensorTag", sensorTagId);
    },
    selectRow(item) {
      if (item.id === this.expandedItem.id) {
        this.expandedItem = "";
      } else this.expandedItem = item;
    },
    remove(item) {
      this.chips.splice(this.chips.indexOf(item), 1);
      this.chips = [...this.chips];
    },
    startScanner() {
      this.scanner = true;
      this.newSensor = true;
    },
    async addSensor() {
      let newSensor = await this.$store.dispatch(
        "sensor/addSensor",
        this.sensor
      );
      if (newSensor) {
        this.$router.push("/");
        // this.$refs.gplaces.changePlace(null);
        // this.$refs.gplaces.element.value = "";
        // this.sensor = JSON.parse(JSON.stringify(this.sensorCopy));
        // this.newSensor = false;
      } else {
        // console.log("Sensor not added");
      }
    },
    async removeSensor(id) {
      await this.$store.dispatch("sensor/removeSensor", id);
    },
    onPlaceChanged(val) {
      if (!val) return;
      this.sensor.location = {
        id: val.place_id,
        name: val.name,
        address: val.formatted_address.split(",")[0],
        city: val.locality,
        state: val.administrative_area_level_1,
        zip: val.postal_code
      };
    },
    setTag(val) {
      this.tag = val;
    },
    onNoResult(val) {
      // console.log(val);
    },
    async addTag() {
      // create new tags
      let newTags = await Promise.all(
        this.chips
          .filter(item => {
            return !this.tagNames.includes(item);
          })
          .map(async item => {
            return await this.$store.dispatch("sensor/addTag", item);
          })
      );
      // only add tags not on sensor
      let tagsToAdd = this.chips.filter(item => {
        return !this.currentTags.includes(item);
      });
      // find tag ids to add
      let tagIds = tagsToAdd
        .map(item => {
          return this.tags.find(tag => tag.name === item);
        })
        .map(id => id.id);
      // create sensor tags
      let sensorTags = await Promise.all(
        tagIds.map(async item => {
          return await this.$store.dispatch("sensor/addSensorTag", {
            sensor: this.expandedItem.id,
            tag: item
          });
        })
      );
      // clear chips
      this.chips = [];
    }
  }
};
</script>

<style lang="scss">
.v-google-places {
  border-bottom: 1px solid;
}
tr {
  cursor: pointer;
}
</style>